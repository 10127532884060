<template>
  <div
    id="app"
    class="position-relative"
    :class="{
      'unlogged-page': isUnloggedPage,
      'sidebar-open': isSidebarOpen
    }"
    style="min-height: 100vh"
  >
    <TheHeader v-if="authenticated || $route.name == 'statics.terms' || $route.name == 'orders.payment.create'" />
    <TheSidebar v-if="authenticated" />
    <SupportModal
      headerTitle="Precisa de ajuda?"
    />

    <main :class="authenticated || $route.name == 'statics.terms' || $route.name == 'orders.payment.create' ? 'py-5' : ''">
      <div :class="authenticated || $route.name == 'statics.terms' || $route.name == 'orders.payment.create' ? 'container' : ''">

        <div id="global-messages" v-if="errors">
          <BaseAlert
            v-if="errors"
            :content="errors"
            type="error"
            @dismiss-alert="clearGlobalErrors"
          />
        </div>

        <transition name="slide-fade">
          <QuickAlert
            v-if="notification && !notification.alertComponent"
            :type="notification.type"
            :content="notification.content"
          />
        </transition>

        <div id="global-messages" v-if="notification && notification.alertComponent">
          <BaseAlert
            v-if="notification && notification.alertComponent"
            :content="notification.content"
            type="warning"
            fontSize="16"
            @dismiss-alert="clearGlobalNotification"
          />
        </div>

        <router-view/>
      </div>
      <ChatWabeeModal />
    </main>

    <TheFooter v-if="authenticated || $route.name == 'statics.terms' || $route.name == 'orders.payment.create'"/>
  </div>
</template>

<script>

import AuthService from '@/modules/auth/services/auth-service'
import AuthStore from '@/modules/auth/store'
import CampaignsStore from '@/modules/campaigns/store'
import UsersStore from '@/modules/users/store'
import UsersService from '@/modules/users/services/users-service'
import { mapState, mapActions } from 'vuex'
import { registerVuexModule } from '@/utils'
import seo from '@/mixins/seo'

import BaseAlert from '@/components/common/Alert/BaseAlert'
import QuickAlert from '@/components/common/Alert/QuickAlert'
import SupportModal from '@/components/help-center/SupportModal.vue'
import TheHeader from '@/components/headers/TheHeader'
import TheFooter from '@/components/footers/TheFooter'
import TheSidebar from '@/components/sidebars/TheSidebar'
import ChatWabeeModal from '@/components/chat/ChatWabeeModal'

export default {
  name: 'App',
  mixins: [seo],
  components: {
    BaseAlert,
    ChatWabeeModal,
    QuickAlert,
    SupportModal,
    TheFooter,
    TheHeader,
    TheSidebar
  },
  data () {
    return {
      showAlert: true
    }
  },
  metaInfo () {
    return {
      title: this.seoDefaultTitle,
      titleTemplate: `%s | ${this.seoDefaultTitleAppend}`,
      meta: [
        { vmid: 'description', name: 'description', content: this.seoDefaultDescription },
        { vmid: 'og-description', name: 'og:description', content: this.seoDefaultDescription },
        { vmid: 'og-title', name: 'og:title', content: this.seoDefaultTitle },
        { vmid: 'og-url', name: 'og:url', content: this.currentUrl },
        { vmid: 'og-image', name: 'og:image', content: this.seoDefaultImage },
        { vmid: 'og-type', name: 'og:type', content: 'website' }
      ]
    }
  },
  created () {
    registerVuexModule(this.$store, 'auth', AuthStore)
    registerVuexModule(this.$store, 'users', UsersStore)
    registerVuexModule(this.$store, 'campaigns', CampaignsStore)

    // Get the logged user when the page first loads
    this.getLoggedUser()
    // Check for referral code
    this.checkForReferralCode()
  },
  computed: {
    ...mapState('auth', {
      authenticated: state => state.authenticated
    }),
    ...mapState('users', {
      user: state => state.user
    }),
    ...mapState({
      errors: state => state.errors,
      isSidebarOpen: state => state.isSidebarOpen,
      notification: state => state.notification
    }),
    isUnloggedPage () {
      const route = this.$route.name
      if (
        route === 'password.create' ||
        route === 'password.reset' ||
        (route === 'home' && !this.authenticated)
      ) {
        return true
      }
      return false
    }
  },
  methods: {
    ...mapActions(['clearErrors', 'clearNotification']),
    clearGlobalErrors () {
      this.clearErrors()
    },
    clearGlobalNotification () {
      this.clearNotification()
    },
    /**
     * Check URL for referral code
     */
    checkForReferralCode () {
      if (!this.authenticated) {
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString)
        const code = urlParams.get('referral')
        if (code) {
          window.localStorage.setItem('referral', code)
        }
      }
    },
    /**
     * Get logged user
     */
    async getLoggedUser () {
      const language = await UsersService.getCookie('user_language')
      if (language) this.$i18n.locale = language
      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString)
      const token = urlParams.get('impersonate')
      if (token) {
        const originalToken = urlParams.get('original_token')
        await AuthService.impersonate(token, originalToken)

        window.location.href = urlParams.get('page') ?? '/'
      } else {
        // Check if the user is authenticated and if the user in the store is filled
        if (this.authenticated) {
          if (!this.user) {
            try {
              await UsersService.me()
              this.$i18n.locale = this.user.language
            } catch (error) {
              error.handleGlobally && error.handleGlobally()
            }
          }
        }
      }
    },
    dismissAlert () {
      this.showAlert = false
    }
  }
}
</script>

<style lang="scss" scoped>

main {
  padding-bottom: 93px !important;
}

#global-messages {
  position: fixed;
  top: 120px;
  left: calc(50% - 140px);
  width: 280px;
  z-index: 10;

  @include tablet {
    width: 500px;
    left: calc(50% - 250px);
  }
}

main {
  position: relative;
  z-index: 2;

  & > .container {
    max-width: 1100px;
  }
}

#app {
    background-image: url('/images/layout/bg-pattern.png');
    background-repeat: repeat;

    &.unlogged-page {
      background-image: none;
    }
}

.alert-pixel-roads-style {
  position: absolute;
  z-index: 1;
  top: 50px;
  width: 90%;
  left: calc(50% - 45%);

  @include desktop-lg {
    width: 80%;
    left: calc(50% - 40%);
  }

  @media (min-width: 1470px) {
    width: 70%;
    left: calc(50% - 35%);
  }
}

</style>
