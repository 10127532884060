<template>
  <div>
    <button class="chat-button" @click="openChatWabeeModal">
      <i class="fa fa-comment pop-up-chat-icon"></i>
    </button>

    <b-modal
      id="chat-wabee-modal"
      :modal-class="'default-modal'"
      :hide-footer="true"
      :hide-header="true"
      @hidden="closeModal"
    >
      <div class="modal-header">
        <img
          src="https://storage.googleapis.com/neuro-cdn/uploads/83066b07131097625624aa942dc09a4a.png"
          alt="pixie"
          width="50"
          height="50"
        />
        <h5 class="modal-title">{{ $t('agent_pixel_roads') }}</h5>
        <button type="button" class="btn-close" @click="closeModal"></button>
      </div>
      <div class="modal-body text-center p-0">
        <div id="wabee-chat-container"></div>
        <Loader
          v-if="loader"
          :size="'small'"
          :align="'start'"
          class="chat-loader"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import Loader from '@/components/common/Loader/Loader.vue'

export default {
  components: {
    Loader
  },
  data () {
    return {
      loader: false
    }
  },
  methods: {
    closeModal () {
      if (window.WabeeChat && typeof window.WabeeChat.destroy === 'function') {
        window.WabeeChat.destroy()
      }
      this.$bvModal.hide('chat-wabee-modal')
    },
    loadWabeeChat (callback) {
      const script = document.createElement('script')
      script.src = 'https://platform.wabee.ai/scripts/wabee-chat-browser.umd.js'
      script.onload = callback
      document.body.appendChild(script)
    },
    /**
     * Open chat wabee modal
     */
    openChatWabeeModal () {
      this.loader = true
      this.loadWabeeChat(() => {
        if (window.WabeeChat) {
          window.WabeeChat.init({
            target: '#wabee-chat-container',
            apiKey: 'e021157e-7d9b-4be4-83ed-9b6100912883',
            agentUrl: 'https://pixel-roads-assistente-e021.wabee.ai/core/v1',
            width: '100%',
            height: '100%',
            sseUrl: 'https://notifications-hub.wabee.ai/events'
          })
          this.loader = false
          const observer = new MutationObserver(() => {
            const kbdElement = document.querySelector(':where(.css-dev-only-do-not-override-1a0py0v).ant-typography kbd')
            if (kbdElement) {
              kbdElement.innerHTML = `
                <div class="d-flex flex-column gap-3 justify-content-center align-items-center">
                  <img 
                    src="https://storage.googleapis.com/neuro-cdn/uploads/83066b07131097625624aa942dc09a4a.png" 
                    alt="pixie" 
                    width="50" 
                    height="50"
                    style="width: 50px; height: 50px; object-fit: cover; border-radius: 50%;"
                  />
                  <p>Como posso te ajudar hoje?</p>
                </div>
              `
              observer.disconnect()
            }
          })
          observer.observe(document.body, { childList: true, subtree: true })
        }
      })
      this.$bvModal.show('chat-wabee-modal')
    }
  }
}
</script>
