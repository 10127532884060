import Vue from 'vue'
import i18n from '@/plugins/vue-i18n'

/**
 * Format billing profile
 */
Vue.filter('formatBrowser', function (browser) {
  switch (browser) {
    case 'MOBILE':
      return i18n.t('mobile')
    case 'DESKTOP':
      return i18n.t('desktop')
    default:
      return browser
  }
})

/**
 * Format billing profile
 */
Vue.filter('formatBillingProfile', function (profile) {
  switch (profile) {
    case 'ADVERTISER':
      return i18n.t('advertiser')
    case 'COMPANY':
      return i18n.t('company')
    default:
      return profile
  }
})

/**
 * Format campaign status
 */
Vue.filter('formatCampaignStatus', function (status) {
  switch (status) {
    case 'ACTIVE':
      return i18n.t('status_type.active')
    case 'PENDING':
      return i18n.t('status_type.pending')
    case 'FINISHED':
      return i18n.t('status_type.finished')
    case 'PAUSED':
      return i18n.t('status_type.paused')
    case 'ERROR':
      return i18n.t('status_type.error')
    case 'FAILED`':
      return i18n.t('status_type.failed')
    case 'DRAFT':
      return i18n.t('status_type.draft')
    default:
      return status
  }
})

/**
 * Format campaign status
 */
Vue.filter('formatCampaignType', function (type) {
  switch (type) {
    case 'PROGRAMATIC':
      return i18n.t('campaign_type.programmatic')
    case 'NATIVE':
      return i18n.t('campaign_type.native')
    case 'PUSH':
      return i18n.t('campaign_type.push')
    case 'SEARCH':
      return i18n.t('campaign_type.search')
    case 'YOUTUBE':
      return 'YouTube'
    case 'META':
      return i18n.t('campaign_type.meta')
    default:
      return type
  }
})

/**
 * Format creative format
 */
Vue.filter('formatCreativeFormat', function (format) {
  switch (format) {
    case 'DISPLAY':
      return i18n.t('creative_format.display')
    case 'NATIVE_DISPLAY':
      return i18n.t('creative_format.native_display')
    case 'DISPLAY_VIDEO':
      return i18n.t('creative_format.display_video')
    case 'PUSH':
      return i18n.t('creative_format.push')
    case 'SEARCH':
      return i18n.t('creative_format.search')
    case 'YOUTUBE':
      return 'YouTube'
    default:
      return format
  }
})

/**
 * Format credit card brands
 */
Vue.filter('formatCreditCardBrand', function (brand) {
  return brand.toString().toUpperCase()
})

/**
 * Format campaign status
 */
Vue.filter('formatCurrencyType', function (type) {
  switch (type) {
    case 'BRL':
      return 'Real'
    case 'EUR':
      return 'Euro'
    case 'USD':
      return 'Dolar'
    default:
      return type
  }
})

/**
 * Format custom targeting type
 */
Vue.filter('formatCustomTargetingType', function (status) {
  switch (status) {
    case 'BLACKLIST':
      return i18n.t('custom_targeting_type.blacklist')
    case 'EMAIL_AUDIENCE':
      return i18n.t('custom_targeting_type.email_audience')
    case 'SITE_LIST':
      return i18n.t('custom_targeting_type.site_list')
    case 'WISHLIST':
      return i18n.t('custom_targeting_type.wishlist')
    default:
      return status
  }
})

/**
 * Format custom targeting type
 */
Vue.filter('formatCustomTargetingType', function (status) {
  switch (status) {
    case 'BLACKLIST':
      return i18n.t('custom_targeting_type.blacklist')
    case 'EMAIL_AUDIENCE':
      return i18n.t('custom_targeting_type.email_audience')
    case 'SITE_LIST':
      return i18n.t('custom_targeting_type.site_list')
    case 'WISHLIST':
      return i18n.t('custom_targeting_type.wishlist')
    default:
      return status
  }
})

/**
 * Format nfse status
 */
Vue.filter('formatNFSeStatus', function (status) {
  switch (status) {
    case 'ISSUED':
      return i18n.t('nfse_status.issued')
    case 'ERROR':
      return i18n.t('nfse_status.error')
    case 'REJECTED':
      return i18n.t('nfse_status.rejected')
    case 'CANCELED':
      return i18n.t('nfse_status.canceled')
    case 'PENDING':
      return i18n.t('nfse_status.pending')
    case 'CREATED':
      return i18n.t('nfse_status.created')
    case 'WAITING':
      return i18n.t('nfse_status.waiting')
    default:
      return status
  }
})

/**
 * Format objectives
 */
Vue.filter('formatObjective', function (objective) {
  switch (objective) {
    case 'IMPRESSION':
      return i18n.t('objective_type.impression')
    case 'CONVERSION':
      return i18n.t('objective_type.conversion')
    case 'RETARGETING':
      return i18n.t('objective_type.retargeting')
    default:
      return objective
  }
})

/**
 * Format payment
 */
Vue.filter('formatPaymentMethod', function (method) {
  switch (method) {
    case 'CREDIT_CARD':
      return i18n.t('payment_method.credit_card')
    case 'BOLETO':
      return i18n.t('payment_method.boleto')
    case 'PAYPAL':
      return i18n.t('payment_method.paypal')
    case 'PIX':
      return i18n.t('payment_method.pix')
    case 'DEPOSIT':
      return i18n.t('payment_method.deposit')
    default:
      return method
  }
})

/**
 * Format payment status
 */
Vue.filter('formatPaymentStatus', function (status) {
  switch (status) {
    case 'PAID':
      return i18n.t('payment_status.paid')
    case 'PENDING':
      return i18n.t('payment_status.pending')
    case 'PROCESSING':
      return i18n.t('payment_status.processing')
    case 'AUTHORIZED':
      return i18n.t('payment_status.authorized')
    case 'REFUNDED':
      return i18n.t('payment_status.refunded')
    case 'WAITING_PAYMENT':
      return i18n.t('payment_status.waiting_payment')
    case 'PENDING_REFUND':
      return i18n.t('payment_status.pending_refund')
    case 'REFUSED':
      return i18n.t('payment_status.refused')
    case 'CHARGEDBACK':
      return i18n.t('payment_status.chargedback')
    case 'EXPIRED':
      return i18n.t('payment_status.expired')
    case 'CANCELED':
      return i18n.t('payment_status.canceled')
    default:
      return status
  }
})

/**
 * Format status
 */
Vue.filter('formatStatus', function (status) {
  switch (status) {
    case 'ACTIVE':
      return i18n.t('status_type.active')
    case 'INACTIVE':
      return i18n.t('status_type.inactive')
    case 'PENDING':
      return i18n.t('status_type.pending')
    case 'FINISHED':
      return i18n.t('status_type.finished')
    case 'PAUSED':
      return i18n.t('status_type.paused')
    case 'ERROR':
      return i18n.t('status_type.error')
    case 'FAILED`':
      return i18n.t('status_type.failed')
    case 'DRAFT':
      return i18n.t('status_type.draft')
    default:
      return status
  }
})

/**
 * Format user roles
 */
Vue.filter('formatUserRole', function (role) {
  switch (role) {
    case 'administrator':
      return i18n.t('user_role.manager')
    case 'user-manager':
      return i18n.t('user_role.user_manager')
    case 'user':
      return i18n.t('user_role.user')
  }
})

/**
 * Format wallet status to sign
 */
Vue.filter('formatWalletTypeToSignal', function (status) {
  switch (status) {
    case 'CREDIT':
      return '+'
    case 'FAILED_TRANSFER_FUND':
      return '+'
    case 'DEBIT':
      return '-'
    case 'CHARGEBACK':
      return '-'
    case 'REFUND':
      return '-'
    case 'CHECKOUT':
      return '-'
  }
})
